import React, { useEffect } from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import Addresses from "../../components/AccountContent/Addresses"
import AccountNavigation from "../../components/AccountContent/AccountNavigation"

const AddressesPage = ({ location }) => {
  const path = location.pathname

  return (
    <Layout dark bg={colors.accountGrey}>
      <Seo
        title="Addresses Page"
        description="In Good Company Addresses Page"
        fullTitle="Addresses Page"
        path={path}
      />
      <AccountNavigation path={path}>
        <Addresses />
      </AccountNavigation>
    </Layout>
  )
}

export default AddressesPage
