import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { deleteAddress, getAddresses, updateUserData } from "../../services/user/user"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import Loader from "../Core/Loader"
import {
  AccountButtonDark,
  AccountButtonLight,
  FormPopup,
  FormPopupOverlay,
  PopupCloseBtn,
  PopupFormContainer,
  PopupHeaderRow,
  PopupTitle,
  Center,
  LeftPadContainer,
  Info,
  H1
} from "./AccountStyles"
import AddressForm from "./AddressForm"
import { ReactComponent as CloseIcon } from "../../resources/img/svg/close.svg"
import { useUserContext } from "../../context/UserContext"
import OnClickButton from "src/components/Core/OnClickButton"

const Error = styled(Info)`
  color: #e61c1c;
`

const LoaderWrapper = styled.div`
  width: 44px;
  height: 44px;
`

const AddressList = styled.div`
  display: grid;
  gap: 2rem;
  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: minmax(0, 1fr);
  }
`
const AddressCard = styled.div`
  box-shadow: 0 0 #0000, 0 0 #0000, 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem;
  background: #fffefc;
  border-radius: 0.25rem;
  display: flex;
  ${fonts.openSansRegular};
  font-size: 1rem;
  line-height: 1.5;
  color: #1c1a17;
`
const InformationCol = styled.div`
  max-width: 50%;
`
const ControlCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: auto;
  height: 100%;
`
const Default = styled.div`
  padding: 0.5rem;
  background: #f2efeb;
  ${fonts.openSansMedium};
  border-radius: 0.25rem;
  font-size: 0.79rem;
  line-height: 1.25;
  text-transform: uppercase;
  color: #1c1a17;
`

const DefaultBtn = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
  font-size: 0.889rem;
  line-height: 1.5;
  ${fonts.openSansMedium};
  color: #090602;
  &:hover {
    color: ${colors.royalBlue};
  }
`

const Buttons = styled.div`
  height: fit-content;
`
const Copy = styled.div`
  ${fonts.openSansRegular};
  font-size: 1rem;
  line-height: 1.5;
`
const TextBtn = styled.div`
  display: inline-block;
  font-size: 0.889rem;
  line-height: 1.5;
  ${fonts.openSansBold};
  color: #090602;
  background: transparent;
  border: none;
  padding: 0 0.5rem;
  margin: 0;
  margin-right: ${(props) => (props.first ? "0.5rem" : "0")};
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
  cursor: pointer;
  &:hover {
    color: ${colors.royalBlue};
  }
`

const NewAddressBtn = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    width: auto;
  }
`

const IconWrapper = styled.div`
  color: inherit;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const Information = styled.p`
  margin: 0;
  margin-bottom: 2.5rem;
  ${fonts.openSansRegular};
  font-size: 1rem;
  line-height: 1.5;
  color: #1c1a17;
`
const PopupButtons = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
`
const PopupButtonWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${breakpoints.sm};
  @media (min-width: ${breakpoints.md}) {
    width: 50%;
    order: ${(props) => (props.first ? "1" : "2")};
    margin-left: ${(props) => (props.first ? "0" : "1rem")};
    max-width: unset;
  }
`

const Addresses = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [addresses, setAddresses] = useState(null)
  const [address, setAddress] = useState(null)
  const [openEditPopup, setOpenEditPopup] = useState(false)
  const [openNewPopup, setOpenNewPopup] = useState(false)
  const [removePopupId, setRemovePopupId] = useState(null)
  const [removing, setRemoving] = useState(false)
  const {userAddresses, setUserAddresses, user, setUser} = useUserContext()
  const [idToBeDefault, setIdToBeDefault] = useState(null)
  const [settingDefault, setSettingDefault] = useState(false)

  const defaultAddressId = user?.shipping?.account_address_id

  let overlayRef = useRef(null)
  let overlayDefRef = useRef(null)

  useEffect(() => {
    if(userAddresses !== null) {
      setAddresses(userAddresses)
      setIsLoading(false)
    }else {
      getAddresses()
      .then((res) => {
        setAddresses(res.results)
        setUserAddresses(res.results)
        setIsLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setIsError(true)
      })
    }
  }, [])

  const getContent = () => {
    if (isError) {
      return <Error>An error occured</Error>
    }

    if (isLoading) {
      return (
        <Center>
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </Center>
      )
    }

    return (
      <>
        <>
          {addresses && addresses.length > 0 ? (
            <AddressList>
              {addresses.map((address, index) => {
                const {
                  name,
                  address1,
                  address2,
                  city,
                  zip,
                  state,
                  country,
                  id,
                } = address
                return (
                  <AddressCard key={id}>
                    <InformationCol>
                      <Copy>{name}</Copy>
                      <Copy>{address1}</Copy>
                      {address2 && <Copy>{address2}</Copy>}
                      <Copy>{zip ? `${city} ${zip}` : city}</Copy>
                      <Copy>{state ? `${state} ${country}` : country}</Copy>
                    </InformationCol>
                    <ControlCol>
                      <div>
                        {defaultAddressId === id ? (
                          <Default>Default</Default>
                        ) : (
                          <DefaultBtn onClick={() => setIdToBeDefault(id)}>
                            Use as default
                          </DefaultBtn>
                        )}
                      </div>
                      <Buttons>
                        <TextBtn onClick={() => setRemovePopupId(id)}>
                          Remove
                        </TextBtn>
                        <TextBtn onClick={() => handleAddressEdit(address)}>
                          Edit
                        </TextBtn>
                      </Buttons>
                    </ControlCol>
                  </AddressCard>
                )
              })}
            </AddressList>
          ) : (
            <Info>There are no addresses associated with this account.</Info>
          )}
        </>
        <NewAddressBtn>
          <OnClickButton blue onClick={() => setOpenNewPopup(true)}>
            Add New Address
          </OnClickButton>
        </NewAddressBtn>
        <AddressForm
          isOpen={openEditPopup}
          close={closeEditPopup}
          address={address}
          setAddress={setAddress}
          addresses={addresses}
          setAddresses={setAddresses}
          checkBoxName="default"
        />
        <AddressForm
          isOpen={openNewPopup}
          close={() => {
            setOpenNewPopup(false)
          }}
          setAddress={setAddress}
          addresses={addresses}
          setAddresses={setAddresses}
          checkBoxName="defaultNew"
        />
        <FormPopupOverlay
          ref={overlayRef}
          open={removePopupId !== null}
          onClick={closeRemomePopupOnOverlay}
        >
          <FormPopup noScroll>
            <PopupFormContainer>
              <PopupHeaderRow>
                <PopupTitle>Delete address</PopupTitle>
                <PopupCloseBtn onClick={() => setRemovePopupId(null)}>
                  <IconWrapper>
                    <CloseIcon />
                  </IconWrapper>
                </PopupCloseBtn>
              </PopupHeaderRow>
              <Information>
                Are you sure you want to remove this address?
              </Information>
              <PopupButtons>
                <PopupButtonWrapper>
                  <AccountButtonDark onClick={() => removeAddress()}>
                    {removing ? "Removing" : "Yes, remove it."}
                  </AccountButtonDark>
                </PopupButtonWrapper>
                <PopupButtonWrapper first>
                  <AccountButtonLight onClick={() => setRemovePopupId(null)}>
                    No, keep it.
                  </AccountButtonLight>
                </PopupButtonWrapper>
              </PopupButtons>
            </PopupFormContainer>
          </FormPopup>
        </FormPopupOverlay>
        <FormPopupOverlay
          ref={overlayDefRef}
          open={idToBeDefault !== null}
          onClick={closeDefaultPopupOnOverlay}
        >
          <FormPopup noScroll>
            <PopupFormContainer>
              <PopupHeaderRow>
                <PopupTitle>Set default address</PopupTitle>
                <PopupCloseBtn onClick={() => setIdToBeDefault(null)}>
                  <IconWrapper>
                    <CloseIcon />
                  </IconWrapper>
                </PopupCloseBtn>
              </PopupHeaderRow>
              <Information>
                Are you sure you want to make this your default address?
              </Information>
              <PopupButtons>
                <PopupButtonWrapper>
                  <AccountButtonDark
                    onClick={() => handleDefaultChange(idToBeDefault)}
                  >
                    {settingDefault ? "Setting" : "Yes"}
                  </AccountButtonDark>
                </PopupButtonWrapper>
                <PopupButtonWrapper first>
                  <AccountButtonLight onClick={() => setIdToBeDefault(null)}>
                    No
                  </AccountButtonLight>
                </PopupButtonWrapper>
              </PopupButtons>
            </PopupFormContainer>
          </FormPopup>
        </FormPopupOverlay>
      </>
    )
  }

  const removeAddress = async () => {
    let updatedAddresses
    setRemoving(true)
    try {
      const deletedAddress = await deleteAddress(removePopupId)
      updatedAddresses = addresses.filter(
        (address) => address.id !== deletedAddress.id
      )
      if (user.shipping?.account_address_id === deletedAddress.id) {
        const updatedUser = await updateUserData({
          shipping: {
            account_address_id: updatedAddresses[0]?.id, // Find alternative address. Maybe disallow deletion of default address without replacement?
          },
        })
        setUser(updatedUser)
      }
      setAddresses(updatedAddresses)
      setUserAddresses(updatedAddresses)
      setRemovePopupId(null)
      setRemoving(false)
    } catch (e) {
      console.error(e)
      setRemovePopupId(null)
      setRemoving(false)
    }
  }

  const handleAddressEdit = (address) => {
    setAddress(address)
    setOpenEditPopup(true)
  }

  const closeEditPopup = () => {
    setOpenEditPopup(false)
    setAddress(null)
  }

  const handleDefaultChange = async (id) => {
    setSettingDefault(true)
    try {
      const updatedUser = await updateUserData({
        shipping: {
          account_address_id: id 
        }
      })
      setUser(updatedUser)
      setSettingDefault(false)
      setIdToBeDefault(null)
    } catch (err) {
      console.error(err)
    }
  }

  const closeRemomePopupOnOverlay = (e) => {
    if (overlayRef.current && overlayRef.current === e.target) {
      setRemovePopupId(null)
    }
  }

  const closeDefaultPopupOnOverlay = (e) => {
    if (overlayDefRef.current && overlayDefRef.current === e.target) {
      setIdToBeDefault(null)
    }
  }

  return (
    <>
      <LeftPadContainer>
        <H1>Addresses</H1>
      </LeftPadContainer>
      <LeftPadContainer>{getContent()}</LeftPadContainer>
    </>
  )
}

export default Addresses
